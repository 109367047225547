<template>
  <div class="app-main-content">
    <div class="head">
      <div class="item">
        <a-button type="primary" @click="openWorkTimeModal">
          <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
          登记工时
        </a-button>
      </div>
      <div class="item">
        <a-range-picker separator="至" format="YYYY/MM/DD" value-format="YYYY/MM/DD" class="search-date-range"
                        v-model="dateRange" @change="dateChange" />
      </div>
    </div>
    <div class="main">
      <div class="main-left">
        <a-table
            :data-source="list"
            rowKey="oid"
            :pagination="false"
        >
          <a-table-column title="项目名称" data-index="prj_name" width="260px"></a-table-column>
          <a-table-column title="阶段名称" data-index="phase_name" width="260px"></a-table-column>
          <a-table-column title="工作项名称" data-index="issue_name"></a-table-column>
          <a-table-column title="开始时间" data-index="start_time" width="175px"></a-table-column>
          <a-table-column title="登记工时" data-index="worktime_min" width="150px">
            <template v-slot:default="value">
              <div>
                {{ value }}小时
              </div>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <div class="main-right">
        <div class="wrok-time-info">
          <div class="info-title">今日登记工时</div>
          <div class="info-content"><span>{{ workItemInfo.today_count }}</span><i>小时</i></div>
        </div>
        <div class="wrok-time-info">
          <div class="info-title">本月登记工时</div>
          <div class="info-content"><span>{{ workItemInfo.month_count }}</span><i>小时</i></div>
        </div>
      </div>
    </div>
    <create-work-time-modal ref="workTimeModal" @reload="reload"></create-work-time-modal>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin";
import createWorkTimeModal from "@/components/createWorkTimeModal.vue";
import {fetch} from "@/utils/request";

export default {
  mixins: [listMixin],
  data() {
    return {
      listMixinOptions: {
        url: "/prj/worktimelog/retrieve",
      },
      dateRange: [],
      listQuery: {
        begin_time: '',
        end_time: '',
        page_size: 999,
        sta_type: '0'
      },
      workItemInfo: {}
    }
  },
  components: { createWorkTimeModal },
  created() {
  },
  mounted() {
    this.getWorkTimeInfo()
  },
  methods: {
    dateChange(date) {
      console.log(date);
      this.listQuery = {
        ...this.listQuery,
        begin_time: date[0] || '',
        end_time: date[1] || ''
      }
      this.reload()
    },
    getWorkTimeInfo() {
      fetch('post', '/prj/worktimelog/sta', this.listQuery).then((res) => {
        if (res.status === 0) {
          this.workItemInfo = res.data
        }
      })
    },
    openWorkTimeModal() {
      this.$refs.workTimeModal.open('create')
    },
    reload() {
      this.getList()
      this.getWorkTimeInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  background-color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    /deep/ .item {
      .ant-calendar-picker-input {
        width: 220px;
      }
    }
  }

  .main {
    display: flex;
    flex: 1;
    overflow: hidden;
    .main-left {
      flex: 1;
      overflow-y: auto;
    }

    .main-right {
      width: 580px;
      flex-shrink: 0;
      padding-left: 10px;
      display: flex;

      .wrok-time-info {
        width: 280px;
        height: 199px;
        border-radius: 4px 4px 4px 4px;
        background: #ffffff;
        box-shadow: 0 2px 10px 0 #d4d4d44d;
        margin-right: 8px;

        .info-title {
          font-size: 16px;
          line-height: 16px;
          text-align: left;
          color: #4D4D4D;
          margin-bottom: 63px;
          padding: 18px 0 0 26px;
        }

        .info-content {
          color: #4D4D4D;
          display: flex;
          align-items: baseline;
          justify-content: center;

          > span {
            font-weight: 700;
            font-size: 50px;
            text-align: left;
            margin-right: 11px;
            line-height: 39px;
          }

          > i {
            font-weight: 500;
            line-height: 15px;
            font-size: 16px;
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>
